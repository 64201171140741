<script setup>
import { useForm } from '@/composables/useForm'

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue'])

const form = useForm();
provide('form', form);

const {
    validate,
    resetValidation,
    reset,
    isValid
} = form;

watch(isValid, (value) => { 
    emit('update:modelValue', value);
})


defineExpose({
    validate,
    resetValidation,
    reset,
    isValid
})

</script>
<template>
    <div class="v-form">
        <slot></slot>
    </div>
</template>

<style scoped>
.v-form{
    padding: 0;
    margin: 0;
}
</style>