import { ref } from "vue";

export function useForm() {

    const inputs: any[] = [];
    const isValid = ref(null as any);

    function reset() {
        inputs.map(el => el.reset() & el.resetValidation());
    }

    function resetValidation() {
        inputs.map(el => el.resetValidation());
    }

    function validateChilds() {
        const errors = inputs.map(el => el.validate())
        const isValid = errors.every(er => er.length == 0);

        return isValid;
    }

    function register(el: any) {
        inputs.push(el);
    }

    function unregister(el: any) {
        const index = inputs.findIndex(e => e == el);
        inputs.splice(index, 1);
    }

    function validate() {
        isValid.value = validateChilds()
        return isValid.value;
    }

    return {
        isValid,
        register,
        unregister,
        reset,
        resetValidation,
        validate,
    }
}